<template>
  <div class="Toolbar text-shadow text-center">
    <template v-for="i in icons">
      <template v-if="!i.disable">
        <div v-if="i.html" :title="i.title" @click="$emit(i.name);doTarget(i.event)" :v-html="i.html">
        </div>
        <div v-else :title="i.title" @click="$emit(i.name);doTarget(i.event)">
          <i :class="i.class||'fas fa-close'"></i>
        </div>
      </template>
    </template>
    <template v-if="showMoreIcon">
      <slot name="hideIcons"></slot>
      <a v-if="chatUrl" title="联系作者" class="text-shadow" :href="chatUrl">
        <i class="fas fa-comments" />
      </a>
    </template>
    <template v-else>
      <div title="显示更多按钮" @click="showMoreIcon=true">
        <i class="fas fa-ellipsis-v" />
      </div>
    </template>
    <slot name="icons"></slot>
    <template v-if="hasAudio">
      <div v-if="hasAudioPlay" title="暂停" @click="$emit('pauseAudio')">
        <i class="fas fa-volume-down"></i>
      </div>
      <div v-else title="播放" @click="$emit('playAudio')">
        <i class="fas fa-volume-mute" />
      </div>
    </template>
    <!--<div title="分享" @click="ShareQrcode">
      <i class="fas fa-share-alt" />
    </div>-->
    <div title="点赞" @click="$emit('PraiseCount')">
      <i class="fas fa-thumbs-up" :class="{disabled:!!Praiseed}" />
    </div>
    <div title="留言" @click="$emit('showWrite')">
      <svg class="icon drop-shadow" width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M44 6H4V36H13V41L23 36H44V6Z" fill="#fff" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M14 19.5V22.5" stroke="#000" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M24 19.5V22.5" stroke="#000" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M34 19.5V22.5" stroke="#000" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel" /></svg>
    </div>
    <!--<div title="评论" >
      <i class="fas fa-comment-alt"/>
    </div>-->
    <div v-if="(pano.gpsLat != 0 || pano.gpsLng != 0)" title="地图" @click="$emit('mapModel')">
      <i class="fas fa-map-marked-alt" />
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        showMoreIcon: true,
        htmltest: null
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
      chatUrl() {
        return this.publicData.chatUrl
      },
      tour() {
        return this.publicData.tour || {}
      },
      audios() {
        return this.publicData.audios || {}
      },
      audiosConfig() {
        return this.publicData.audiosConfig || {}
      },
      icons() {
        //return [
        //  {
        //    title: 'WebVR',
        //    class: 'fas fa-vr-cardboard',
        //    name: 'WebVR',
        //    event: {
        //      target: 'PanoToolbar', name: 'enterVR'
        //    }
        //  },
        //  {
        //    title: '陀螺仪',
        //    class: 'icon icon-gyroscope drop-shadow',
        //    name: 'WebVR',
        //    event: {
        //      target: 'PanoToolbar', name: 'enterVR'
        //    }
        //  },
        //]
        return { ...this.config.icons, ...this.publicData.icons, }
      },
      hasAudio() {
        for (var i in this.audios) {
          if (this.audios[i]) {
            return true
          }
        }
        return false
      },
      hasAudioPlay() {
        for (var i in this.audios) {
          if (this.audiosConfig[i] && this.audiosConfig[i].play) {
            return true
          }
        }
        return false
      },
      Praiseed() {
        return this.publicData.Praiseed || 0
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          showWrite: {
            target: 'writeComment', name: 'showWrite'
          },
          PraiseCount: {
            target: 'count', name: 'PraiseCount'
          },
          playAudio: {
            target: 'audio', name: 'playAll'
          },
          pauseAudio: {
            target: 'audio', name: 'pauseAll'
          },
          mapModel: {
            target: 'panoMap', name: 'show'
          },
        },
        funcs: {
          doTarget: this.doTarget,
        }
      })
    },
    destroyed() {
    },
    methods: {
      doTarget(e) {
        if (!e) {
          return
        }
        return this.getFunc(e)()
      },
    },
  }
</script>
<style scoped>
  .Toolbar {
    font-size: 1.8rem;
    line-height: 3.2rem;
    opacity: 0.6
  }

    .Toolbar > * {
      cursor: pointer;
    }

    .Toolbar i {
      padding: 8px;
    }

    .Toolbar .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .Toolbar .disabledState {
      opacity: 0.5;
    }
</style>
